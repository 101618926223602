<template>
  <!-- 面包屑 -->
  <div class="measure">
    <crumbs class="breadcrumb-container" />
    <!-- 头部功能 -->
    <div class="header">
      <div class="header-operation">
        <ul>
          <li>
            <router-link to="/measure">
              <img src="../../../assets/images/scale-manage.png" alt />
            </router-link>
          </li>
          <li>
            <router-link to="/measure/measure_classify">
              <img src="../../../assets/images/scale-class.png" alt />
            </router-link>
          </li>
          <li>
            <router-link to="/measure/measure_group">
              <img src="../../../assets/images/scale-grouping.png" alt />
            </router-link>
          </li>
          <li>
            <router-link to="/measure/add_measure">
              <img src="../../../assets/images/scale-add.png" alt />
            </router-link>
          </li>
        </ul>
      </div>
      <div class="header-tool">
        <div class="del" @click="delDatas" v-if="isShow">
          <img src="../../../assets/images/del.png" alt />
        </div>
        <div class="refresh" @click="refresh()">
          <img src="../../../assets/images/refresh.png" alt />
        </div>
      </div>
    </div>
    <transition name="fade-transform" mode="out-in">
      <router-view ref="childer"></router-view>
    </transition>
  </div>
</template>
<script>
import Crumbs from "../../../components/crumbs"; //面包屑
import {
  deleteMeasure,
  delMeasureClassifyList,
  delMeasureGroupList
} from "@/api/evaluate.js";
export default {
  inject: ["reload"],
  components: {
    Crumbs
  },
  data() {
    return {
      isShow: true
    };
  },
  created() {},
  updated() {
    ////console.log(this.$route.name);
    if (this.$route.name == "add_measure") {
      this.isShow = false;
    } else {
      this.isShow = true;
    }
  },
  methods: {
    // 刷新
    refresh() {
      this.reload();
    },
    // 全选删除
    delDatas() {
      // 判断是否有数据
      if (this.$refs.childer.multipleSelection.length <= 0) {
        this.$message({
          showClose: true,
          message: "请先勾选要删除的数据",
          type: "warning"
        });
        return;
      }
      //  提示 是否删除
      this.$confirm("是否删除已勾选的数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          let data = this.$refs.childer.multipleSelection;
          data = data.toString();
          if (this.$route.name == "measure_classify") {
            delMeasureClassifyList({ id: data }).then(res => {
              if (res.code === 400200) {
                this.$message({
                  message: "删除成功!",
                  type: "success"
                });
                this.$refs.childer.loadMeasureListData();
              }
            });
          } else if (this.$route.name == "home_measure") {
            deleteMeasure({ id: data }).then(res => {
              if (res.code === 400200) {
                this.$message({
                  message: "删除成功!",
                  type: "success"
                });
                this.$refs.childer.loadmeasureListData();
              }
            });
          } else if (this.$route.name == "measure_group") {
            delMeasureGroupList({ id: data }).then(async res => {
              this.$message({
                message: res.msg,
                type: "success"
              });
              this.$refs.childer.loadMeasureListData();
            });
          }
        })
        .catch(() => {});
    }
  }
};
</script>

<style lang="less">
// 头部
.header {
  width: 90%;
  margin: 0 auto;
  display: flex;
  padding-top: 20px;
  .header-operation {
    ul {
      display: flex;
      li {
        //  float: left;
        width: 150px;
        list-style: none;
        padding: 0 30px;
        text-align: center;
        img {
          width: 100%;
          // pointer-events: none;
        }
      }
    }
  }
  .header-tool {
    margin-left: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    .del {
      width: 120px;
      cursor: pointer;
    }
    .refresh {
      width: 120px;
      cursor: pointer;
    }
    img {
      width: 100%;
      pointer-events: none;
    }
  }
}
.measure {
  height: 100%;
}
.breadcrumb-container {
  width: 100%;
  border-bottom: 1px solid rgb(217, 242, 247);
}
</style>